import React from 'react'

import Title from '../atoms/title'
import Link from '../atoms/link'
import BodyCopy from '../atoms/body-copy'
import Lists from '../atoms/lists'
import KeyFeatures from '../molecules/key-features'
import QuestionnaireTeaser from '../molecules/questionnaire-teaser'

const Search = ({
  content
}) => {
  return (
    <div className='co-search'>
      <div className='l-section'>
        <Title
            type='h1'
            title={content.section1.title1}
            classNames='co-search__title'
        />
        <Link 
          url={content.section1.image.linkUrl}
          align='center'
          classNames='co-search__image l-spacing__m-tb--24'
          type="image"
        >
          <img src={require(`../../images/${content.section1.image.src}`)} alt={content.section1.image.alt} />
        </Link>
      </div>

      <div className='l-section'>
        <div className='l-grid__row'>
          <div className='l-grid__col-xs-12 l-grid__col-lg'>
            <div className='l-container l-container--col'>
              <Title
                type='h2'
                title={content.section2.title1}
                classNames='co-search__title co-search__title--1 l-spacing__m-b--32'
              />
              <Lists
                items={content.section2.list}
                classNames='co-search__list-checkmark l-spacing__p-b--8'
                checkmark
              />
              <QuestionnaireTeaser 
                content={content.questionnaireTeaser}
                classNames='co-search__questionnaire-teaser co-search__questionnaire-teaser--mobile u-desktop__hide l-spacing__m-tb--40'
              />
              <Title
                type='h3'
                title={content.section2.title2}
                align='left'
                color='dark'
                weight='bold'
                classNames='co-search__title co-search__title--2 l-spacing__m-t--40'
              />
              <BodyCopy
                copy={content.section2.bodyCopy}
                classNames='co-search__copy'
              />
            </div>
          </div>

          <div className='l-grid__col-xs-12 l-grid__col-lg'>
            <div className='l-container l-container--col'>
              <QuestionnaireTeaser 
                content={content.questionnaireTeaser}
                classNames='co-search__questionnaire-teaser co-search__questionnaire-teaser--desktop u-desktop__show'
              />
              <KeyFeatures
                features={content.keyFeatures}
                type='mini'
                classNames='u-desktop__show'
              />
            </div>
          </div>
        </div>
      </div>

      <div className='l-section l-section--t l-section--pop'>
        <div className='l-container l-container--pop'>
          <Title
            type='h3'
            title={content.section3.title1}
            align='left'
            color='dark'
            weight='bold'
            classNames='co-search__title'
          />

          <Lists
            items={content.section3.list}
            classNames='co-search__list'
          />

          <Link
            url={content.section3.image.linkUrl}
            align='center'
            classNames='co-search__image l-spacing__m-tb--40'
            type="image"
          >
            <img src={require(`../../images/${content.section3.image.src}`)} alt={content.section3.image.alt} />
          </Link>
        </div>
      </div>

      <div className='l-section'>
        <div className='l-container l-container--inner'>
          <KeyFeatures
            features={content.keyFeatures}
          />
        </div>
      </div>

    </div>
  )
}

export default Search
